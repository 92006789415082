@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nanum+Gothic&display=swap');

body {
  background-color: #E6FF00;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Custom scroll bars */
/* Para navegadores basados en Webkit (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #1a1a1a;
}

::-webkit-scrollbar-thumb {
  background: #4d4d4d;
}

::-webkit-scrollbar-thumb:hover {
  background: #2e2e2e;
}

/* Para Firefox */
html {
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #1a1a1a;
}

html::-webkit-scrollbar {
  width: 12px;
}

html::-webkit-scrollbar-track {
  background: #1a1a1a;
}

html::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
  border: none;
}

/* Custom scroll bars */